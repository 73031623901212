import { DiscoverAwsResourceConfigurationOutput, ScanOperations } from '@amzn/alkimia-model';
import { CognitoToken } from 'amazon-cognito-auth-ts';
import axios from 'axios';
import {
  constructQueryString,
  getLambdaApiUrl,
  getStandardHeader,
  processApiError,
} from '../../utils';

const mock = false;

// scanAwsAccount Lambda function can take up to 900 seconds.  What value will a user tolerate in a UI?
export const timeoutAfterSeconds = 900;

export const scanAwsAccount = async (
  token: CognitoToken,
  customerAccountId: string | undefined,
  customerAccountRegion: string,
): Promise<DiscoverAwsResourceConfigurationOutput> => {
  if (!customerAccountId) throw new Error('Missing required input - awsAccountId');
  if (mock) { return Promise.resolve(mockResponse); }
  return new Promise((resolve) => {
    const startTime = Date.now();
    let scanOperation = ScanOperations.startScan;
    const intervalRetry = setInterval(() => {
      axios.get(`${getLambdaApiUrl()}/datastore/scanAwsAccount?${constructQueryString({ customerAccountId, customerAccountRegion, scanOperation })}`,
        {
          headers: getStandardHeader(token),
          timeout: 4000,
        })
        .then((result) => {
          if (result.status === 200) {
            clearInterval(intervalRetry);
            resolve({ messages: [], services: result.data });
          }
          if (Date.now() - startTime > timeoutAfterSeconds * 1000) {
            clearInterval(intervalRetry);
            resolve({ messages: [`Timed out after ${timeoutAfterSeconds} seconds`], services: result.data ?? [] });
          }
          if (scanOperation === ScanOperations.startScan) {
            scanOperation = ScanOperations.getScan;
          }
        })
        .catch((error) => resolve(processApiError('scanAwsAccount', error)));
    }, 5000);
  });
};

const mockResponse: DiscoverAwsResourceConfigurationOutput = {
  services: [
    {
      sourceAccountId: '861399335178',
      sourceRegion: 'us-east-1',
      resourceType: 'AWS::DataSync::LocationS3',
      sourceType: 'Config',
      resourceKey: '2278f24c4aab789c61853ce7bb1ad0d2',
      ids: {},
      selected: false,
      relevant: true,
      supportedConstructType: 'L1',
      clusterId: 'someClusterId123',
      configuration: {
        version: '1.3',
        accountId: '861399335178',
        configurationItemCaptureTime: '2023-09-18T19:36:35.930Z',
        configurationItemStatus: 'ResourceDiscovered',
        configurationStateId: '1695065795930',
        configurationItemMD5Hash: '',
        arn: 'arn:aws:datasync:us-east-1:861399335178:location/loc-0d23812ed8898502b',
        resourceType: 'AWS::DataSync::LocationS3',
        resourceId: 'loc-0d23812ed8898502b',
        awsRegion: 'us-east-1',
        availabilityZone: 'Regional',
        resourceCreationTime: '2023-09-18T19:35:15.457Z',
        tags: {},
        relatedEvents: [],
        relationships: [],
        configuration: {
          S3Config: {
            BucketAccessRoleArn: 'arn:aws:iam::861399335178:role/service-role/AWSDataSyncS3BucketAccess-alkimia-test-bucket',
          },
          S3BucketArn: 'arn:aws:s3:::alkimia-test-bucket',
          Subdirectory: '/xytestdatasyncs3',
          S3StorageClass: 'STANDARD',
          LocationUri: 's3://alkimia-test-bucket/xytestdatasyncs3/',
          LocationArn: 'arn:aws:datasync:us-east-1:861399335178:location/loc-0d23812ed8898502b',
        },
        supplementaryConfiguration: {
          CreationTime: '2023-09-18T19:35:15.457Z',
        },
        resourceTransitionStatus: 'None',
      },
    },
    {
      sourceAccountId: '861399335178',
      sourceRegion: 'us-east-1',
      resourceType: 'AWS::SNS::Subscription',
      resourceKey: '0ea4dd012f52ec213983185ad04d1b4b',
      sourceType: 'SDK',
      ids: {},
      selected: false,
      relevant: true,
      supportedConstructType: 'L1',
      clusterId: 'someClusterId',
      configuration: {
        Subscription: {
          SubscriptionPrincipal: 'arn:aws:iam::861399335178:role/IibsAdminAccess-DO-NOT-DELETE',
          Owner: '861399335178',
          RawMessageDelivery: 'false',
          TopicArn: 'arn:aws:sns:us-east-1:861399335178:AlkimiaTestManualSNSTopic',
          Endpoint: 'xuansh@amazon.com',
          RedrivePolicy: '{"deadLetterTargetArn":"arn:aws:sqs:us-east-1:861399335178:test-queue"}',
          Protocol: 'email',
          PendingConfirmation: 'false',
          ConfirmationWasAuthenticated: 'false',
          SubscriptionArn: 'arn:aws:sns:us-east-1:861399335178:AlkimiaTestManualSNSTopic:7826c4c8-b6f4-4961-8986-cd6489744c32',
        },
      },
    },
  ],
  messages: [
    'test error message1',
    'test error message2',
  ],
};
